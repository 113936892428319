import React from 'react';
import { graphql } from 'gatsby';
import Layout from 'components/Layout';
import styled from '@emotion/styled';
import dimensions from 'styles/dimensions';
import SEO from 'components/SEO';
import Hero from 'components/Hero';
import CaseStudyCard from 'components/_card/CaseStudy';
import Quote from 'components/Quote';

const CaseStudyQuote = styled.div`
  padding: 8rem 0;
  
  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    padding: 12rem 0;
  }
`;

const RenderBody = ({ work }) => (
  <>
    <Hero
      title={work.introduction_heading}
    />

    {(work.body && work.body.length > 0) && work.body.map((section, i) => (
      <section key={i}>
        {section.type === 'case_study_list' &&
          section.fields.map((study, i) => {
            if (i % 2 === 0) {
              return (
                <CaseStudyCard
                  key={i}
                  align="left"
                  uid={study.case_study._meta.uid}
                  image={study.case_study.feature_image}
                  name={study.case_study.client_name}
                  area={study.case_study.area}
                  tagline={study.case_study.tagline}
                />
              );
            } else {
              return (
                <CaseStudyCard
                  key={i}
                  align="right"
                  uid={study.case_study._meta.uid}
                  image={study.case_study.feature_image}
                  name={study.case_study.client_name}
                  area={study.case_study.area}
                  tagline={study.case_study.tagline}
                />
              );
            }
          })
        }

        {section.type === 'quote' &&
          <CaseStudyQuote>
            <Quote quote={section.primary.quote}/>
          </CaseStudyQuote>
        }
      </section>
    ))}
  </>
);

export default ({ data }) => {
  //Required check for no data being returned
  const work = data.prismic.allWorks.edges[0].node;

  if (!work) return null;

  return (
    <Layout headerStyle={'dark'}>
      <SEO
        title={work.social_title || 'All Work'}
        description={work.social_description ? work.social_description : null}
        image={work.social_image ? work.social_image.url : null}
      />

      <RenderBody work={work}/>
    </Layout>
  );
}

export const query = graphql`
  {
    prismic {
      allWorks {
        edges {
          node {
            introduction_heading
            introduction_content
            body {
              ... on PRISMIC_WorkBodyCase_study_list {
                type
                fields {
                  case_study {
                    ... on PRISMIC_Case_study {
                      _meta {
                        uid
                      }
                      client_name
                      tagline
                      area {
                        ... on PRISMIC_Area {
                          name
                        }
                      }
                      feature_image
                    }
                  }
                }
              }
              ... on PRISMIC_WorkBodyQuote {
                type
                primary {
                  quote {
                    ... on PRISMIC_Quote {
                      content
                      name
                      title
                    }
                  }
                }
              }
            }
            social_title
            social_description
            social_image
          }
        }
      }
    }
  }
`;
